import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

// components
import Breadcrumbs from './../../components/BreadCrumbs';

// scss
import './Faq.css';
import './../../scss/globals/entry.scss';

// images
import logoSmall from "../../img/ryoshilogo.png";
import migration from "../../img/migration.webp";


const Migration = () => {
  const [socialUrl] = useState(window.location.href);

  const [activeListItem, setActiveListItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const headings = document.querySelectorAll("h2, h3, h4");
      const lastHeadingInView = Array.from(headings).reverse().find((heading) => {
        const bounding = heading.getBoundingClientRect();
        return bounding.bottom >= 0 && bounding.bottom <= window.innerHeight;
      });
      if (lastHeadingInView) {
        const id = lastHeadingInView.getAttribute("id");
        setActiveListItem(id);
      } else {
        const firstListItem = document.querySelector(".sidebar li:first-of-type");
        const listItemBounding = firstListItem.getBoundingClientRect();
        if (listItemBounding.top > 0) {
          setActiveListItem(null);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const jsonLd = {
    "@type": "Organization",
    "name": "Ryoshi Token",
    "url": "https://ryoshitoken.com/",
    "sameAs": [
      "https://www.instagram.com/ryoshitoken",
      "https://twitter.com/TokenRyoshi",
      "https://www.facebook.com/Ryoshitoken/",
    ],
    "logo": logoSmall,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hi@ryoshitoken.com",
      "contactType": "Customer service"
    },
    "tableOfContents": {
      "@type": "ItemList",
      "name": "Table of Contents",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": "1",
          "name": "Introduction",
          "url": socialUrl + "#introduction"
        },
        {
          "@type": "ListItem",
          "position": "2",
          "name": "Hero Section Best Practices",
          "url": socialUrl + "#practices"
        }
      ]
    }
  };

  return (
    [
      <Helmet>
        <title>Ryoshi — Shiba's Godfather Returns Home, deflationary Ryoshi BSC token</title>
        <link rel="canonical" href="https://ryoshitoken.com/whitepaper"/>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>,

      <Breadcrumbs/>,

      <p className="time-stamp">Page last updated: <time>March 25, 2023</time></p>,

      <section className="entry">
        <div className="grid-x justify">
          <div className="cell-8 cnt">
            <h1>RYOSHI <small>BSC</small> V2<br/>
              MIGRATION <small>GUIDE</small></h1>

            <p className="enlarged">
              Ryoshi V1 token holders can migrate their tokens to Ryoshi V2 tokens by sending them to a migration
              address and filling out a form. The migration will be open for 6 months, and V2 token holders will receive
              an airdrop with a value greater than 1:1. Holders of Ryoshi V1 tokens on HotBit or LBank do not need to do
              anything as the migration will be done automatically. Ryoshi V2 offers new opportunities, including
              Ryocash stable coin and a partnership with a debit card provider.
            </p>

            <hr/>

            <h4 id="guide">If you have Ryoshi V1 tokens, you can migrate them to Ryoshi V2 tokens. Here's how:</h4>
            <ol>
              <li>Send your Ryoshi V1 tokens to this migration address: 0xAbAbfB467F77C60c4381c70AdEDbdB0f4157bd2e</li>
              <li>Fill out <a href="https://docs.google.com/forms/d/1cCEz3iiUF1htGciyMmBcNnM4UOlEmy_guenH8DihHoQ/edit" title="fill out this migration form" target="_blank" rel="noreferrer">this form</a> after you send your V1 tokens</li>
            </ol>

            <figure>
              <a href="https://docs.google.com/forms/d/1cCEz3iiUF1htGciyMmBcNnM4UOlEmy_guenH8DihHoQ/edit" title="fill out this migration form" target="_blank" rel="noreferrer"><img loading="lazy" width="934" height="484" src={migration} alt="placeholder image"/></a>
            </figure>

            <h4 id="things">Some things to note about the migration:</h4>
            <ul>
              <li>Migration will be open for 6 months</li>
              <li>You will receive an airdrop of V2 tokens when they go live</li>
              <li>The value of your V2 tokens will be greater than 1:1</li>
              <li>If you already migrated using the <a href="https://migrate.ryoshitoken.com/" target="_blank" title="Ryoshi Migration Program DAPP" rel="dofollow">DAPP before trading closed</a>, you don't need to fill out the form</li>
            </ul>

            <p>If you are a holder of Ryoshi V1 tokens on Hotbit or Lbank, everything will be done automatically for you.</p>
            <p>Do not buy any more Ryoshi V1 tokens, as a snapshot was taken before liquidity was pulled for V2.</p>

            <h2 id="why-migrate">Why should you migrate to Ryoshi V2</h2>
            <h4>Here are some reasons:</h4>
            <ul>
              <li>Ryoshi V1 hit a market cap of 45 million without a marketing wallet. With proper funding, the development and listings possibilities are immense</li>
              <li>Ryocash stable coin will soon be added to the ecosystem</li>
              <li>Ryoshi V2 is partnering with a debit card provider, allowing holders to load RyoCash and use it like cash</li>
              <li>Ryoshi Dex will be launching on Shibarium blockchain</li>
            </ul>
          </div>

          <div className="cell-4 sidebar">
            <input id="tblcnt" type="checkbox"/>
            <label htmlFor="tblcnt">
              Table of contents
            </label>

            <ul>
              <li className={activeListItem === 'guide' ? 'active' : ''}><a href="#guide">Quick guide</a>
              </li>
              <li className={activeListItem === 'things' ? 'active' : ''}><a href="#things">Some things to note</a>
              </li>
              <li className={activeListItem === 'why-migrate' ? 'active' : ''}><a href="#why-migrate">Why should you migrate to Ryoshi V2</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    ]
  );
};

export default Migration;