import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const menuRef = useRef(null);
  const menuToggleRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsActive(false);
        menuToggleRef.current.classList.remove("active");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, menuToggleRef]);

  const toggleMenu = () => {
    if (isActive) {
      setIsActive(false);
      menuRef.current.classList.remove("active");
      menuToggleRef.current.classList.remove("active");
    } else {
      setIsActive(true);
      menuRef.current.classList.add("active");
      menuToggleRef.current.classList.add("active");
    }
  };

  const handleLinkClick = () => {
    setIsActive(false);
    menuRef.current.classList.remove("active");
    menuToggleRef.current.classList.remove("active");
  };

  return (
    <nav className="navigation">
      <div>
        <Link className="rint" to="./" title="Ryoshi">
          <i className="ryoshi"></i>
          <span className="chat">
          <span className="boss">Ryoshi</span>
          <span className="message">Hey, buddy!<br/>
              Looking for something?</span>
        </span>
        </Link>
      </div>

      {location.pathname === "/" && (
        <ul className={`submenu ${isActive ? "active" : ""}`} ref={menuRef}>
          <li>
            <a href="#story" title="Story" onClick={handleLinkClick}>
              Story
            </a>
          </li>
          <li>
            <a href="#roadmap" title="Roadmap" onClick={handleLinkClick}>
              Roadmap
            </a>
          </li>
          <li>
            <a href="#tokenomics" title="Tokenomics" onClick={handleLinkClick}>
              Tokenomics
            </a>
          </li>
          <li>
            <a href="#team" title="Team" onClick={handleLinkClick}>
              Team
            </a>
          </li>
        </ul>
      )}

      <div className="btn-group">
        <a
          className="btn primary"
          href="https://pancakeswap.finance/swap?outputCurrency=0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5"
          title="Buy"
          rel="nofollow"
          target="_blank"
        >
          Buy
        </a>
        <a
          className="btn outline"
          href="https://t.me/ryoshitokenofficial"
          title="Ryoshi Telegram"
          rel="dofollow"
          target="_blank"
        >
          Community
        </a>
        {location.pathname === "/" && (
          <button
            className={`btn secondary ${isActive ? "active" : ""}`}
            id="menuToggle"
            onClick={toggleMenu}
            ref={menuToggleRef}
          >
          <span>
            <span></span>
            <span></span>
          </span>
          </button>
        )}
      </div>
    </nav>
  );
};

export default Header;