import React from 'react';
import { Link } from "react-router-dom";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="marquee marquee-right bordered">
        <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small></h4></div>
        <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;buy ryoshi &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>buy ryoshi</small></h4></div>
      </div>

      <div className="grid-x stretch split neg">
        <div className="cell-6">
          <p className="lbl">Quickboard</p>

          <section>
            <h3 className="text-center">
              <Link to="./whitepaper" title="Check Blueprint aka whitepaper"><small>Check</small> Blueprint <small>aka whitepaper</small></Link><br/>
              <a href="#" target="_blank" rel="nofollow" title="Use Media Pack to hype Ryoshi"><small>Use</small> Media Pack <small>to hype</small> Ryoshi</a><br/>
              <a href="https://migrate.ryoshitoken.com/" target="_blank" rel="nofollow" title="Simple Migration dApp">CatCat <small>App Coming</small> Soon</a>
            </h3>
          </section>
        </div>

        <div className="cell-6 align-center">
          <p className="lbl">Community</p>

          <section>
            <ul className="socials arranged">
              <li><a className="circle" href="https://t.me/ryoshitokenofficial" target="_blank" title="Ryoshi Telegram"><i data-ico="telegram"></i></a></li>
              <li><a className="circle" href="https://www.instagram.com/ryoshitoken" target="_blank" title="Ryoshi Instagram"><i data-ico="instagram"></i></a></li>
              <li><a className="circle" href="https://twitter.com/TokenRyoshi" target="_blank" title="Ryoshi Twitter"><i data-ico="twitter"></i></a></li>
              <li><a className="circle" href="https://www.facebook.com/Ryoshitoken/" target="_blank" title="Ryoshi Facebook"><i data-ico="facebook"></i></a></li>
              <li><a className="circle" href="#" target="_blank" title="Ryoshi TikTok"><i data-ico="tiktok"></i></a></li>
            </ul>
          </section>
        </div>

        <hr/>
      </div>

      <div className="footer-ref justify-self">
        <p className="lbl"><span>Ryoshi © {currentYear} <a href="mailto:hi@ryoshitoken.com" rel="nofollow" target="_blank">hi@ryoshitoken.com</a></span></p>
        <p className="flinks"><a href="https://coinmarketcap.com/currencies/ryoshi-token/" rel="noreferrer" title="CoinMarketCap" target="_blank">CoinMarketCap</a> <a href="https://www.coingecko.com/en/coins/ryoshi-token" rel="noreferrer" title="CoinGeko" target="_blank">CoinGeko</a> <a href="https://poocoin.app/tokens/0xb350aebaedb1ed3269b0e25d5e593a9bb4b9f9d5" rel="noreferrer" title="PooCoin" target="_blank">PooCoin</a> <span>Powered by <a href="https://ezypx.com/" title="EZYpx Design On-Demand Services" target="_blank" rel="dofollow">EZYpx</a></span></p>
      </div>
    </footer>
  );
};

export default Footer;