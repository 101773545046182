import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const WhitePaperNav = () => {
  const { pathname } = useLocation();

  const isActive = (path) => {
    return path === pathname ? 'active' : '';
  }

  return (
    <ul>
      <li className={isActive('/whitepaper')}>
        <Link to="/whitepaper" title="Ryoshi Token">Ryoshi Token</Link>
        <ul>
          <li className={isActive('/whitepaper/why-ryoshi')}>
            <Link className="disable" to="/whitepaper/why-ryoshi" title="Why Ryoshi">Why Ryoshi</Link>
          </li>
          <li className={isActive('/whitepaper/features')}>
            <Link className="disable" to="/whitepaper/features" title="Features">Features</Link>
            <ul className="disable">
              <li className={isActive('/whitepaper/features/cash')}>
                <Link to="/whitepaper/features/cash" title="RYO Cash">RYO Cash</Link>
              </li>
              <li className={isActive('/whitepaper/features/citcat')}>
                <Link to="/whitepaper/features/citcat" title="CitCat">CitCat</Link>
              </li>
              <li className={isActive('/whitepaper/features/dex')}>
                <Link to="/whitepaper/features/dex" title="Dex">Dex</Link>
              </li>
              <li className={isActive('/whitepaper/features/swap')}>
                <Link to="/whitepaper/features/swap" title="Swap">Swap</Link>
              </li>
            </ul>
          </li>
          <li className={isActive('/whitepaper/tokenomics')}>
            <Link to="/whitepaper/tokenomics" title="Ryoshi Token">Tokenomics</Link>
          </li>
          <li className={isActive('/whitepaper/roadmap')}>
            <Link to="/whitepaper/roadmap" title="Roadmap">Roadmap</Link>
          </li>
        </ul>
      </li>
      <li className={isActive('/whitepaper/getting-started')}>
        <Link className="disable" to="/whitepaper/getting-started" title="Getting Started">Getting Started</Link>
      </li>
    </ul>
  )
}

export default WhitePaperNav;