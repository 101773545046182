import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';

// components
import Breadcrumbs from './../../components/BreadCrumbs';
import WhitePaperNav from "../../components/WhitePaperNav";

// scss
import './Whitepaper.css';
import './../../scss/globals/entry.scss';

// images
import logoSmall from "../../img/ryoshilogo.png";


const Roadmap = () => {
  const [socialUrl] = useState(window.location.href);

  const [activeListItem, setActiveListItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const headings = document.querySelectorAll("h2, h3, h4");
      const lastHeadingInView = Array.from(headings).reverse().find((heading) => {
        const bounding = heading.getBoundingClientRect();
        return bounding.bottom >= 0 && bounding.bottom <= window.innerHeight;
      });
      if (lastHeadingInView) {
        const id = lastHeadingInView.getAttribute("id");
        setActiveListItem(id);
      } else {
        const firstListItem = document.querySelector(".sidebar li:first-of-type");
        const listItemBounding = firstListItem.getBoundingClientRect();
        if (listItemBounding.top > 0) {
          setActiveListItem(null);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const jsonLd = {
    "@type": "Organization",
    "name": "Ryoshi Token",
    "url": "https://ryoshitoken.com/",
    "sameAs": [
      "https://www.instagram.com/ryoshitoken",
      "https://twitter.com/TokenRyoshi",
      "https://www.facebook.com/Ryoshitoken/",
    ],
    "logo": logoSmall,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hi@ryoshitoken.com",
      "contactType": "Customer service"
    },
    "tableOfContents": {
      "@type": "ItemList",
      "name": "Table of Contents",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": "1",
          "name": "Phase 1 - Relaunch",
          "url": socialUrl + "#phase-1"
        },
        {
          "@type": "ListItem",
          "position": "2",
          "name": "Phase 2 - Utilities",
          "url": socialUrl + "#phase-2"
        },
        {
          "@type": "ListItem",
          "position": "3",
          "name": "Phase 3 - Branding",
          "url": socialUrl + "#phase-3"
        },
        {
          "@type": "ListItem",
          "position": "4",
          "name": "Phase 4 - Expansion",
          "url": socialUrl + "#phase-4"
        },
        {
          "@type": "ListItem",
          "position": "5",
          "name": "Phase 5 - Growth",
          "url": socialUrl + "#phase-5"
        }
      ]
    }
  };

  return (
    [
      <Helmet>
        <title>Ryoshi — ShibFather Returns Home, deflationary Ryoshi BSC token</title>
        <link rel="canonical" href="https://ryoshitoken.com/whitepaper"/>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>,

      <Breadcrumbs/>,

      <p className="time-stamp">Page last updated: <time>March 27, 2023</time></p>,

      <section className="entry">
        <div className="grid-x justify">
          <div className="cell-3 wp-nav">
            <input id="bpmenu" type="checkbox"/>
            <label htmlFor="bpmenu">
              Blueprint Menu
            </label>

            <WhitePaperNav/>
          </div>
          <div className="cell-6 cnt">
            <h1>Roadmap</h1>

            <p className="enlarged">
              We've put together a roadmap that highlights the different phases and milestones we're aiming to achieve.
              While this roadmap serves as a helpful guide, it's important to remember that our plans may evolve and
              change as we move forward.
            </p>

            <hr/>

            <h2 id="phase-1">Phase 1 - Relaunch</h2>
            <ul className="checklist">
              <li className="active">Ryoshi Token V2 Contract</li>
              <li className="active">Rebranding 1.0</li>
              <li className="active">dApp Migration Program</li>
              <li className="active">Migration Airdrop 1.0 [Open for 6mo]</li>
              <li className="active">Website 1.0 Launch</li>
              <li>Ryoshi Token V2 Launch</li>
              <li>Liquidity Locked <a
                href="https://mudra.website/?certificate=yes&type=0&lp=0x2554938bb70426cb13a5b4202bbf59a2e91311fa"
                target="_blank" rel="nofollow" title="Liquidity Locked">[link]</a></li>
              <li>Contract Audit</li>
              <li>Blueprint aka Whitepaper 1.0 release</li>
              <li>Marketing Campaigns 1.0
                <ol>
                  <li>Twitter Marketing & Influencer Shout Outs</li>
                  <li>CoinMarketCap Trending</li>
                  <li>DexTools Trending</li>
                </ol>
              </li>
            </ul>

            <h2 id="phase-2">Phase 2 - Utilities</h2>
            <ul className="checklist">
              <li>Ryo Cash - Stable Coin
                <ol>
                  <li>Contract development</li>
                  <li>Reserve - Funds Accumulation</li>
                  <li>Ryo Cash Release</li>
                </ol>
              </li>
              <li>CitCat App
                <ol>
                  <li className="active">Debit Card Partnership</li>
                  <li>Design & Development</li>
                  <li>Contract Integration</li>
                  <li>Alpha Testing</li>
                  <li>Closed Beta Release</li>
                  <li>Public Release</li>
                </ol>
              </li>
              <li>App & Token Marketing Campaigns 1.1
                <ol>
                  <li>Twitter Marketing & Influencer Shout Outs</li>
                  <li>Competitions & Giveaways</li>
                </ol>
              </li>
            </ul>

            <h2 id="phase-3">Phase 3 - Branding</h2>
            <ul className="checklist">
              <li>Merch Design</li>
              <li>Ryo Store</li>
              <li>Media Pack</li>
              <li>Competitions & Giveaways</li>
              <li>AMAs</li>
            </ul>

            <h2 id="phase-4">Phase 4 - Expansion</h2>
            <ul className="checklist">
              <li>Whisker Stake
                <ol>
                  <li>Contract Development</li>
                  <li>Contract Audit</li>
                  <li>Stake Design & Development</li>
                  <li>Closed Beta Release</li>
                  <li>Public Release</li>
                </ol>
              </li>
              <li>Whisker Swap
                <ol>
                  <li>Contract Development</li>
                  <li>Contract Audit</li>
                  <li>Swap Design & Development</li>
                  <li>Closed Beta Release</li>
                  <li>Public Release</li>
                </ol>
              </li>
              <li>Whisker DEX
                <ol>
                  <li>Design & Development</li>
                  <li>Closed Beta Release</li>
                  <li>Public Release</li>
                </ol>
              </li>
              <li>Marketing Campaigns 1.2
                <ol>
                  <li>Twitter Marketing & Influencer Shout Outs</li>
                  <li>Competitions & Giveaways</li>
                  <li>Press Releases</li>
                </ol>
              </li>
            </ul>

            <h2 id="phase-5">Phase 5 - Growth</h2>
            <ul className="checklist">
              <li>Ryo Bridge
                <ol>
                  <li>Contract Development</li>
                  <li>Contract Audit</li>
                  <li>Design & Development</li>
                  <li>Closed Beta Release</li>
                  <li>Public Release</li>
                </ol>
              </li>
              <li>Partnerships
                <ol>
                  <li>Initial CEX Listing</li>
                  <li>Project Partnerships</li>
                  <li>Out-Of-Home Advertising - Billboards</li>
                </ol>
              </li>
              <li>Marketing Campaigns 1.3
                <ol>
                  <li>Twitter Marketing & Influencer Shout Outs</li>
                  <li>Competitions & Giveaways</li>
                </ol>
              </li>
              <li>Secret Utility</li>
            </ul>
          </div>

          <div className="cell-3 sidebar">
            <input id="tblcnt" type="checkbox"/>
            <label htmlFor="tblcnt">
              Table of contents
            </label>

            <ul>
              <li className={activeListItem === 'phase-1' ? 'active' : ''}><a href="#phase-1">Phase 1 - Relaunch</a>
              </li>
              <li className={activeListItem === 'phase-2' ? 'active' : ''}><a href="#phase-2">Phase 2 - Utilities</a>
              </li>
              <li className={activeListItem === 'phase-3' ? 'active' : ''}><a href="#phase-3">Phase 3 - Branding</a>
              </li>
              <li className={activeListItem === 'phase-4' ? 'active' : ''}><a href="#phase-4">Phase 4 - Expansion</a>
              </li>
              <li className={activeListItem === 'phase-5' ? 'active' : ''}><a href="#phase-5">Phase 5 - Growth</a></li>
            </ul>
          </div>
        </div>
      </section>
    ]
  );
};

export default Roadmap;