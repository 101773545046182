import React, {useState, useRef, useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Link} from "react-router-dom";
import {Parallax} from 'react-scroll-parallax';
import {useMediaQuery} from 'react-responsive';

// components
import Focus from './../../components/Focus';
import VideoPlayer from './../../components/VideoPlayer';
import Clipboard from "../../components/Clipboard";
import Fameboard from "../../components/Fameboard";
import Questionnaire from "../../components/Questionnaire";

// scss
import './Home.scss';
import './../../scss/components/marquee.scss';
// images
import logoSmall from "../../img/ryoshilogo.png";
import ockBoss from "../../img/ock-boss.webp";
import flashBackCol1 from "../../img/flashback-col1.webp";
import flashBackCol2a from "../../img/flashback-nine-lives.webp";
import flashBackCol2b from "../../img/flashback-col2-2.webp";
import flashBackCol2c from "../../img/flashback-col2-3.webp";
import flashBackCol3 from "../../img/flashback-col3.webp";
import flashBackThumb from "../../img/flashbackThumb.webp";
import flashBackVideo from "../../video/bitboy-short.mp4";
import flashBackCol4 from "../../img/flashback-col4.webp";
import flashBackCol4a from "../../img/flashback-col4a.webp";
import flashBackCol4b from "../../img/flashback-col4b.webp";
import flashBackCol5 from "../../img/flashback-col5.webp";
import join from "../../img/join.svg";
import clues1 from "../../img/clue1.webp";
import clues2 from "../../img/clue2.webp";
import clues3 from "../../img/clue3.webp";
import clues4 from "../../img/clue4.webp";
import citcatapp from "../../img/citcat-app.webp";
import blueprintFig from "../../img/blueprint.svg";
import blueprintSide from "../../img/blueprint-side.svg";
import shibariumBlock from "../../img/shibarium-block.webp";
import arrowDwn from "../../img/icons/arrow-dwn.svg";
import arrowright from "../../img/icons/arrow-right.svg";


const Home = () => {
  const isMobile = useMediaQuery({query: "(max-width: 768px)"});

  const jsonLd = {
    "@type": "Organization",
    "name": "Ryoshi Token",
    "url": "https://ryoshitoken.com/",
    "sameAs": [
      "https://www.instagram.com/ryoshitoken",
      "https://twitter.com/TokenRyoshi",
      "https://www.facebook.com/Ryoshitoken/",
    ],
    "logo": logoSmall,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hi@ryoshitoken.com",
      "contactType": "Customer service"
    },
  };

  const [showNew, setShowNew] = useState(false);

  const handleClick = () => {
    setShowNew(!showNew);
  };

  const [showQuestionnaire, setShowQuestionnaire] = useState(false);
  const questionnaireRef = useRef(null);

  const handleClickOutside = (event) => {
    if (questionnaireRef.current && !questionnaireRef.current.contains(event.target)) {
      setShowQuestionnaire(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleToggleQuestionnaire = () => {
    setShowQuestionnaire(!showQuestionnaire);
  };

  const handleQuizClose = () => {
    setShowQuestionnaire(false);
  };

  return (
    [
      <Helmet>
        <title>Ryoshi — ShibFather Returns Home, deflationary Ryoshi BSC token</title>
        <link rel="canonical" href="https://ryoshitoken.com/"/>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>,

      <section id="intro">
        <h3 className="opening">Empty Streets <small>of</small> &nbsp;&nbsp;&nbsp;Shibarium <small>Block</small><br/>
          coming back &nbsp;&nbsp;&nbsp;Bigger &nbsp;&nbsp;<small>better</small> wiser</h3>
        <h3 className="text-right"><span><img loading="lazy" width="60" height="29" src={arrowright}
                                              title="Coming soon Ryo Cash"/> Coming soon</span> <small>Ryo
          Cash</small><br/> <small>reserve backed</small> stable coin</h3>
        <Focus/>
        <div className="marquee marquee-right bordered">
          <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>newsstand, Articles featuring Ryoshi's Return</small> &nbsp;&nbsp;<a
              href="https://www.techtimes.com/articles/267822/20211110/ryoshi-token-the-revolutionary-meme-token-that-has-it-all.htm"
              rel="noreferrer" title="Tech Times" target="_blank">Tech times</a> &nbsp;&nbsp;Yahoo
            news &nbsp;&nbsp;<a
              href="https://techbullion.com/ryoshi-token-the-revolutionary-meme-token-that-has-it-all/" rel="noreferrer"
              title="MarketWatch" target="_blank">TechBullion</a> &nbsp;&nbsp;<a
              href="https://finance.yahoo.com/news/ryoshi-revolutionary-community-driven-entertainment-074000885.html?.tsrc=fin-srch"
              rel="noreferrer" title="Yahoo Finance" target="_blank">Yahoo
              finance</a> &nbsp;&nbsp;<a
              href="https://www.marketwatch.com/press-release/ryoshi-a-revolutionary-community-driven-entertainment-token-2021-11-11?tesla=y"
              rel="noreferrer" title="MarketWatch" target="_blank">Marketwatch</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>as a resident
              in</small> &nbsp;&nbsp;<a href="https://coinmarketcap.com/currencies/ryoshi-token/" rel="noreferrer"
                                        title="CoinMarketCap" target="_blank">CoinMarketCap</a> &nbsp;&nbsp;<a
              href="https://www.coingecko.com/en/coins/ryoshi-token" rel="noreferrer" title="CoinGeko"
              target="_blank">coingeko</a> &nbsp;&nbsp;<a href="#" rel="noreferrer" title="LBank"
                                                          target="_blank">lbank</a> &nbsp;&nbsp;<a href="#"
                                                                                                   rel="noreferrer"
                                                                                                   title="HotBit"
                                                                                                   target="_blank">hotbit</a>
            &nbsp;&nbsp;<small> charts</small>&nbsp;&nbsp;<a
              href="https://poocoin.app/tokens/0xb350aebaedb1ed3269b0e25d5e593a9bb4b9f9d5" rel="noreferrer"
              title="PooCoin" target="_blank">PooCoin</a>
          </h4></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>newsstand, Articles featuring Ryoshi's Return</small> &nbsp;&nbsp;<a
              href="https://www.techtimes.com/articles/267822/20211110/ryoshi-token-the-revolutionary-meme-token-that-has-it-all.htm"
              rel="noreferrer" title="Tech Times" target="_blank">Tech times</a> &nbsp;&nbsp;Yahoo
            news &nbsp;&nbsp;<a
              href="https://techbullion.com/ryoshi-token-the-revolutionary-meme-token-that-has-it-all/" rel="noreferrer"
              title="MarketWatch" target="_blank">TechBullion</a> &nbsp;&nbsp;<a
              href="https://finance.yahoo.com/news/ryoshi-revolutionary-community-driven-entertainment-074000885.html?.tsrc=fin-srch"
              rel="noreferrer" title="Yahoo Finance" target="_blank">Yahoo
              finance</a> &nbsp;&nbsp;<a
              href="https://www.marketwatch.com/press-release/ryoshi-a-revolutionary-community-driven-entertainment-token-2021-11-11?tesla=y"
              rel="noreferrer" title="MarketWatch" target="_blank">Marketwatch</a> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>as a resident
              in</small> &nbsp;&nbsp;<a href="https://coinmarketcap.com/currencies/ryoshi-token/" rel="noreferrer"
                                        title="CoinMarketCap" target="_blank">CoinMarketCap</a> &nbsp;&nbsp;<a
              href="https://www.coingecko.com/en/coins/ryoshi-token" rel="noreferrer" title="CoinGeko"
              target="_blank">coingeko</a> &nbsp;&nbsp;<a href="#" rel="noreferrer" title="LBank"
                                                          target="_blank">lbank</a> &nbsp;&nbsp;<a href="#"
                                                                                                   rel="noreferrer"
                                                                                                   title="HotBit"
                                                                                                   target="_blank">hotbit</a>
            &nbsp;&nbsp;<small> charts</small>&nbsp;&nbsp;<a
              href="https://poocoin.app/tokens/0xb350aebaedb1ed3269b0e25d5e593a9bb4b9f9d5" rel="noreferrer"
              title="PooCoin" target="_blank">PooCoin</a>
          </h4></div>
        </div>
      </section>,

      <section id="flashbacks">
        <div className="grid-x no-gutters">
          <div className="ock">
            <Parallax
              translateY={['0px', '250px']}
              disabled={isMobile}
            >
              <h2 className="enlarged">the<br/>
                Organized<br/>
                Crime Kittens</h2>
            </Parallax>
          </div>
          <div className="ock-img">
            <Parallax
              translateY={['0px', '-150px']}
              translateX={['0px', '-75px']}
              rotate={[15, 0]}
            >
              <figure>
                <img loading="lazy" width="669" height="669" src={ockBoss}
                     alt="I can't help but think back to the Good Times - the gang times - and the old photos of the Organized Crime Kittens. These flashbacks serve as a reminder."/>
              </figure>
            </Parallax>
          </div>
        </div>

        <div className="grid-x no-gutters flashbacks-gang">
          <div className="flashback-1">
            <Parallax
              translateY={['0px', '-90px']}
              translateX={['0px', '45px']}
              rotate={[-7, 0]}
            >
              <figure>
                <img loading="lazy" width="333" height="743" src={flashBackCol1} alt="flashback"/>
              </figure>
            </Parallax>
          </div>
          <div className="flashback-2">
            <figure>
              <Parallax
                translateY={['0px', '-50px']}
              >
                <img loading="lazy" width="333" height="450" src={flashBackCol2a} alt="flashback"/>
              </Parallax>
              <Parallax
                translateY={['0px', '80px']}
                translateX={['0px', '-35px']}
                rotate={[-13, 0]}
              >
                <a href="https://twitter.com/nyjah/status/1405643248052232194?s=21" title="Nyjah Huston" target="_blank"
                   rel="noreferrer"><img loading="lazy" width="334" height="131" src={flashBackCol2b}
                                         alt="Nyjah Huston"/></a>
                <a href="https://twitter.com/masikakalysha/status/1456635119976923143?s=46&t=F7nJC0enpNLO4TjSzG7YiQ"
                   title="Masika Kalysha" target="_blank" rel="noreferrer"><img loading="lazy" width="334" height="131"
                                                                                src={flashBackCol2c}
                                                                                alt="Nyjah Huston"/></a>
              </Parallax>
            </figure>
          </div>
          <div className="flashback-3">
            <figure>
              <Parallax
                translateY={['0px', '80px']}
                rotate={[15, 0]}
                scale={[1, 1.15]}
              >
                <img loading="lazy" width="333" height="281" src={flashBackCol3} alt="flashback"/>
              </Parallax>
            </figure>
          </div>
          <div className="flashback-4 bitboy">
            <figure>
              <Parallax
                translateY={['0px', '-55px']}
              >
                <a href="https://twitter.com/souljaboy/status/1408163843134529539?s=20" title="Soulja Boy"
                   target="_blank" rel="noreferrer"><img className="push" loading="lazy" width="333" height="203"
                                                         src={flashBackCol4a} alt="flashback"/></a>

                <div className="w-embed overlay" itemProp="video" itemScope itemType="https://schema.org/VideoObject">
                  <meta itemProp="name" content="Ryoshi Flashbacks Bitboy"/>
                  <meta itemProp="duration" content="PT4S"/>
                  <meta itemProp="uploadDate" content="2023-3-22T08:00:00+08:00"/>
                  <meta itemProp="thumbnailUrl" content={flashBackThumb}/>
                  <meta itemProp="description"
                        content="Bitboy Bought 50k US dollars worth of Ryoshi Tokens"/>
                  <VideoPlayer src={flashBackVideo}/>
                </div>

                <img loading="lazy" width="333" height="283" src={flashBackCol4} alt="flashback"/>
              </Parallax>

              <Parallax
                translateY={['0px', '40px']}
                translateX={['-20px', '10px']}
                rotate={[18, 0]}
              >
                <a href="https://twitter.com/1goonrich/status/1459928298960691201?s=46&t=F7nJC0enpNLO4TjSzG7YiQ"
                   title="Crypto Messiah" target="_blank" rel="noreferrer"><img loading="lazy" width="333" height="131"
                                                                                src={flashBackCol4b}
                                                                                alt="flashback"/></a>
              </Parallax>
            </figure>
          </div>
          <div className="flashback-5">
            <figure>
              <Parallax
                translateY={['150px', '-90px']}
                rotate={[-5, 0]}
              >
                <img loading="lazy" width="333" height="779" src={flashBackCol5} alt="flashback"/>
              </Parallax>
            </figure>
          </div>
        </div>

        <div className="grid-x joiner stretch">
          <div className="cell-3">
            <h3>
              Ryoshi <small>is</small> bringing<br/>
              <small>his old</small> Gang Together
            </h3>
          </div>

          <div className="cell-6 join">
            <Parallax
              translateY={['-120px', '0px']}
              scale={[0.6, 1]}
            >
              <img loading="lazy" width="749" height="696" src={join} alt="Join"/>
            </Parallax>
          </div>

          <div className="cell-3 text-right">
            <h3>
              New <mall>in the</mall> hood<br/>
              Get in &nbsp;&nbsp;&nbsp;<small>We nEED you</small>
            </h3>

            <ul className="socials self-bottom">
              <li><a className="circle" href="https://www.instagram.com/ryoshitoken" target="_blank"
                     title="Ryoshi Instagram"><i data-ico="instagram"></i></a></li>
              <li><a className="circle" href="https://www.facebook.com/Ryoshitoken/" target="_blank"
                     title="Ryoshi Facebook"><i data-ico="facebook"></i></a></li>
              <li><a className="circle" href="#" target="_blank" title="Ryoshi TikTok"><i data-ico="tiktok"></i></a>
              </li>
              <li><a className="circle" href="https://twitter.com/TokenRyoshi" target="_blank" title="Ryoshi Twitter"><i
                data-ico="twitter"></i></a></li>
              <li><a className="circle" href="https://t.me/ryoshitokenofficial" target="_blank" title="Ryoshi Telegram"><i
                data-ico="telegram"></i></a></li>
            </ul>
          </div>
        </div>

        <div className="grid-x split remain voter">
          <hr/>
          <div className="cell-6">
            <p className="lbl">Wise choice</p>
            <button onClick={handleToggleQuestionnaire}>
              <span className="scale">YES</span>
            </button>
          </div>
          <div className="cell-6">
            <p className="lbl">Risky choice</p>
            <button onClick={handleToggleQuestionnaire}>
          <span className="framer">
            <span>NO</span>
            <span>YES</span>
          </span>
            </button>
          </div>
          <hr/>
        </div>

        {showQuestionnaire && (
          <div className="overlayer">
            <button onClick={handleQuizClose} className="close"><span></span></button>
            <Questionnaire ref={questionnaireRef} onClose={handleQuizClose}/>
          </div>
        )}
      </section>,

      <section id="story">
        <div className="grid-x">
          <div className="cell-6">
            <Parallax
              translateY={['150px', '-300px']}
              disabled={isMobile}
            >
              <h2>the<br className="show-large"/> Story<br className="show-large"/> of Ryoshi</h2>
            </Parallax>
          </div>

          <div className="cell-6">
            <Parallax
              translateY={['0px', '-100px']}
              disabled={isMobile}
            >
              <h2>
                <br className="show-large"/>
                ShibFather<br className="show-large"/> Returns<br className="show-large"/> home
              </h2>
              <p className="enlarged fl">
                Ryoshi took a deep breath as he walked through Shibarium Block for the first time in 2 years, his eyes
                scanning every corner of the once-thriving community. It was a shadow of its former self, with buildings
                in disrepair and the streets littered with trash. The meme energy that had once fueled the hood was
                fading, and Ryoshi knew that something needed to be done.
              </p>
            </Parallax>
          </div>
        </div>
      </section>,

      <section className="report fw neg">
        <Parallax
          translateX={['-25%', '0%']}
        >
          <div className="slideshow">
            <figure>
              <img loading="lazy" width="443" height="604" src={clues1} alt="Ryoshi in Shibarium Block Scene 1"/>
            </figure>

            <figure>
              <img loading="lazy" width="443" height="604" src={clues2} alt="Ryoshi in Shibarium Block Scene 2"/>
            </figure>

            <figure>
              <img loading="lazy" width="443" height="604" src={clues3} alt="Ryoshi in Shibarium Block Scene 3"/>
            </figure>

            <figure>
              <img loading="lazy" width="443" height="604" src={clues4} alt="Ryoshi in Shibarium Block Scene 4"/>
            </figure>

            <figure>
              <img loading="lazy" width="443" height="604" src={clues1} alt="Ryoshi in Shibarium Block Scene 5"/>
            </figure>
          </div>
        </Parallax>
      </section>,

      <section id="roadmap" className="fw run-line">
        <div className="marquee marquee-right">
          <div className="marquee-el" style={{animationDuration: '45s'}}><h2 className="enlarge">Time for Changes - Time
            for a New Chapter - RYOSHI is Back -&nbsp;</h2></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}><h2 className="enlarge">Time for Changes - Time
            for a New Chapter - RYOSHI is Back -&nbsp;</h2></div>
        </div>
      </section>,

      <section id="gangMeeting">
        <div className="grid-x">
          <div className="cell-5">
            <h3>
              <small>it was</small> time <small>to</small> call in<br/>
              <small>his</small> old gang <small>members</small>
            </h3>
          </div>

          <div className="cell-7">
            <p className="enlarged fl">
              Ryoshi sat at the head of the table, his closest allies surrounding him. The dimly lit room was filled
              with the smell and smoke of Catnip cigars, and the sound of heated discussion. They had come together to
              discuss the future of Shibarium Block, a place that was dear to each of their hearts. They had all grown
              up there, lived there, and watched it deteriorate over the years in Ryoshi's absence.
            </p>
          </div>

          <hr className="v-small"/>

          <div className="cell-7 offset-5">
            <div className="grid-x">
              <div className="cell-6">
                <p>He cleared his throat and began to speak. "Gentlemen, thank you all for coming. As you know,
                  Shibarium Block has been in a state of decline for far too long. It's time for us to take action and
                  turn things around. I've been working with my distant family, and I've learned a thing or two about
                  how to run a successful business. We need to implement a necessary tax that will go towards the
                  block's development and be used to protect it."</p>
              </div>

              <div className="cell-6">
                <p>Ryoshi brought up his second idea. "Business requires changes. Those dog raids are damaging our
                  Catnip networking. We need a cover plan, and I think I have just the thing. Catnip will now be
                  packaged in Whisker branding. No one will suspect a thing, and our business will thrive once again."
                  As the gang continued to discuss their plans, Ryoshi felt a sense of pride and excitement. They were
                  going to change Shibarium Block for the better.</p>
              </div>
            </div>
          </div>
        </div>
      </section>,

      <section className="bp">
        <Parallax
          translateY={['-120px', '0px']}
          scale={[0.6, 1]}
        >
          <div className="fig">
            <img loading="lazy" width="302" height="876" src={blueprintSide} alt="Ryoshi Blueprint AKA Whitepaper"/>
            <img loading="lazy" width="941" height="1082" src={blueprintFig} alt="Ryoshi Blueprint AKA Whitepaper"/>
          </div>
        </Parallax>
      </section>,

      <section className="bp-footer">
        <div className="grid-x stretch split neg">
          <hr/>
          <div className="cell-6">
            <p className="lbl">Community call</p>

            <section>
              <h3 className="text-center">Ryoshi <small>presents</small> His<br/>
                <small>redevelopment</small> Blueprint <small>to</small><br/>
                the Community </h3>
            </section>
          </div>

          <div className="cell-6 align-center">
            <p className="lbl justify"><span>Ryoshi whitepaper</span><span>Updated: 27 / 03 / 2023</span></p>

            <section>
              <Link to="./whitepaper" className="btn primary" title="Read more">
                Read more
              </Link>
            </section>
          </div>
        </div>

        <div className="marquee marquee-right bordered">
          <div className="marquee-el" style={{animationDuration: '45s'}}>
            <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka roadmap</small></h4></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}>
            <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka
                roadmap</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Blueprint &nbsp;&nbsp;&nbsp;outline&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <small>aka roadmap</small></h4></div>
        </div>

        <div className="grid-x stretch split neg reorder">
          <hr/>
          <div className="cell-6 align-center">
            <p className="lbl">Coming soon</p>

            <section className="cc-promo">
              <figure>
                <img loading="lazy" width="880" height="548" src={citcatapp}
                     alt="Ryoshi forms a partnership with a debit card provider enabling RYOC in-life purchases"/>
              </figure>
            </section>
          </div>

          <div className="cell-6">
            <p className="lbl">CitCat App</p>

            <section>
              <h3 className="text-center"><small>forms a</small> partnership <small>with a</small><br
                className="show-large"/>
                <small>debit</small> card <small>provider</small> enabling<br className="show-large"/>
                RYOC <small>in</small>-life <small>purchases</small></h3>
            </section>
          </div>
        </div>

        <div className="marquee marquee-right bordered">
          <div className="marquee-el" style={{animationDuration: '45s'}}>
            <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}>
            <h4>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>use</small> &nbsp;&nbsp;&nbsp;RYO
              Cash &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <small>in
                day-to-day</small> &nbsp;&nbsp;&nbsp;transactions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h4></div>
        </div>
      </section>,

      <section className="fw">
        <ul className="roadmap-list">
          <li>
            <div className="cnt">
              <div>
                <span>Bank</span> <sup>Ryo Cash</sup>
              </div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="finished">Collect reserve <small>funds</small></h3>
                  <h3 className="finished"><small>Develop</small> CitCat App</h3>
                  <h3 className="finished"><small>Launch Stable Coin</small> Ryo Cash</h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="finished">Collect reserve <small>funds</small></h3>
                  <h3 className="finished"><small>Develop</small> CitCat App</h3>
                  <h3 className="finished"><small>Launch Stable Coin</small> Ryo Cash</h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div>
                <span>Suburb</span> <sup>migration</sup>
              </div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="finished">Migration UI <small>development</small></h3>
                  <h3 className="finished">Contract <small>deployment and</small> Testing</h3>
                  <h3 className="finished"><small>Website</small> release</h3>
                  <h3 className="cooking"><small>Blueprint</small> release</h3>
                  <h3 className="cooking"><small>Website</small> update</h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="finished">Migration UI <small>development</small></h3>
                  <h3 className="finished">Contract <small>deployment and</small> Testing</h3>
                  <h3 className="finished"><small>Website</small> release</h3>
                  <h3 className="cooking"><small>Blueprint</small> release</h3>
                  <h3 className="cooking"><small>Website</small> update</h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Casino</span> <sup>swap</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Whisker Swap <small>development</small></h3>
                  <h3 className="cooking">Whisker Swap <small>Beta</small> Testing</h3>
                  <h3 className="cooking">Whisker Swap <small>release</small></h3>
                  <h3 className="cooking"><small>Blueprint</small> release</h3>
                  <h3 className="cooking">Whisker swap <small>limit orders and contract scanner integration</small></h3>
                  <h3 className="cooking">Whisker swap <small>lp locker</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Whisker Swap <small>development</small></h3>
                  <h3 className="cooking">Whisker Swap <small>Beta</small> Testing</h3>
                  <h3 className="cooking">Whisker Swap <small>release</small></h3>
                  <h3 className="cooking"><small>Blueprint</small> release</h3>
                  <h3 className="cooking">Whisker swap <small>limit orders and contract scanner integration</small></h3>
                  <h3 className="cooking">Whisker swap <small>lp locker</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Grocery</span> <sup>dex</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Whisker Dex <small>development</small></h3>
                  <h3 className="cooking">Whisker Dex Beta <small>release</small></h3>
                  <h3 className="cooking">Audit ca <small>and</small> dex</h3>
                  <h3 className="finished">Blueprint <small>update</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Whisker Dex <small>development</small></h3>
                  <h3 className="cooking">Whisker Dex Beta <small>release</small></h3>
                  <h3 className="cooking">Audit ca <small>and</small> dex</h3>
                  <h3 className="finished">Blueprint <small>update</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Bank atm</span> <sup>stake</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Staking <small>and</small> farming <small>development</small></h3>
                  <h3 className="cooking">Press <small>releases</small></h3>
                  <h3 className="cooking"><small>Website</small> update</h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Staking <small>and</small> farming <small>development</small></h3>
                  <h3 className="cooking">Press <small>releases</small></h3>
                  <h3 className="cooking"><small>Website</small> update</h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Kiosk</span> <sup>marketing</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking"><small>Pre</small> launch Marketing <small>and </small>AMA’s</h3>
                  <h3 className="cooking">Giveaways <small>and</small> competitions</h3>
                  <h3 className="cooking"><small>Website</small> release</h3>
                  <h3 className="cooking">Launch<small>on pancakeswap</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking"><small>Pre</small> launch Marketing <small>and </small>AMA’s</h3>
                  <h3 className="cooking">Giveaways <small>and</small> competitions</h3>
                  <h3 className="cooking"><small>Website</small> release</h3>
                  <h3 className="cooking">Launch<small>on pancakeswap</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Cafe</span> <sup>partnerships</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">First CEX <small>listing</small></h3>
                  <h3 className="cooking">Different <small>locations</small> billboards</h3>
                  <h3 className="cooking">Socials <small>media</small> marketing</h3>
                  <h3 className="cooking">Influencers / Projects <small>partnerships</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">First CEX <small>listing</small></h3>
                  <h3 className="cooking">Different <small>locations</small> billboards</h3>
                  <h3 className="cooking">Socials <small>media</small> marketing</h3>
                  <h3 className="cooking">Influencers / Projects <small>partnerships</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Bridge</span> <sup>crosschain</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>development</small></h3>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>beta testing</small></h3>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>release</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>development</small></h3>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>beta testing</small></h3>
                  <h3 className="cooking"><small>Cross Chain</small> Bridge <small>release</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>Lab</span> <sup>secret utility</sup></div>
            </div>
            <section className="details">
              <div className="marquee marquee-right">
                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Ryoshipad <small>release</small></h3>
                  <h3 className="cooking">Tier 1 exchange <small>listing</small></h3>
                  <h3 className="cooking">Project Expansion</h3>
                  <h3 className="cooking">Secret Utility <small>reveal</small></h3>
                </div>

                <div className="marquee-el" style={{animationDuration: '45s'}}>
                  <h3 className="cooking">Ryoshipad <small>release</small></h3>
                  <h3 className="cooking">Tier 1 exchange <small>listing</small></h3>
                  <h3 className="cooking">Project Expansion</h3>
                  <h3 className="cooking">Secret Utility <small>reveal</small></h3>
                </div>
              </div>
            </section>
          </li>

          <li>
            <div className="cnt">
              <div><span>...More</span></div>
            </div>
          </li>
        </ul>
      </section>,

      <section>
        <div className="marquee marquee-right bordered">
          <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;Yes,
            Gang &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>I'm back</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And I
            have a plan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>we
              are</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;going &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to take
            over &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shibarium Block, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>step
              by</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>and</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;this is our plan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>aka roadmap</small></h4></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}><h4> &nbsp;&nbsp;&nbsp;Yes,
            Gang &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>I'm back</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;And I
            have a plan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>we
              are</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;going &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;to take
            over &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Shibarium Block, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<small>step
              by</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Step &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>and</small> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;this is our plan &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <small>aka roadmap</small></h4></div>
        </div>

        <div className="grid-container">
          <div className="grid-item title"><span className="line"></span> <span className="heading">i. Phase</span>
          </div>
          <div className="grid-item indi" rowspan="2">
            <div className="current"><span>Migration</span> <span className="per">1 month</span></div>
          </div>
          <div className="grid-item title"><span className="line"></span> <span className="heading">ii. Phase</span>
          </div>
          <div className="grid-item indi" rowspan="2">
            <div><span>Swap</span> <span className="per">1 month</span></div>
          </div>
          <div className="grid-item title"><span className="line"></span> <span className="heading">iii. Phase</span>
          </div>
          <div className="grid-item indi" rowspan="4">
            <div><span>Partnerships</span> <span className="per">2 months</span></div>
          </div>
          <div className="grid-item title"><span className="line"></span> <span className="heading">iv. Phase</span>
          </div>
          <div className="grid-item indi" rowspan="3">
            <div><span>Ryoshi Pad</span> <span className="per">4 months</span></div>
          </div>
          <div className="grid-item month" rowspan="2"><span className="v"></span><span className="m">Mar 23</span><span
            className="v"></span></div>
          <div className="grid-item month" rowspan="2"><span className="v"></span><span className="m">Apr 23</span><span
            className="v"></span></div>
          <div className="grid-item month" rowspan="2"><span className="v"></span><span className="m">May 23</span><span
            className="v"></span></div>
          <div className="grid-item month" rowspan="2"><span className="v"></span><span className="m">Jul 23</span><span
            className="v"></span></div>
        </div>
      </section>,

      <section className="fw neg" id="shibariumBlock">
        <div className="shibnav">
          <div className="counter">
            <h4><small>FOUND</small>&nbsp;&nbsp; <span>0</span> <small>OF</small> 10 &nbsp;&nbsp;<small>Whisker
              safekeeping spots</small></h4>
          </div>

          <div className="controls">
            <button><span>Sound on</span>
              <div className="circle sound-on"></div>
            </button>
          </div>
        </div>

        <figure>
          <img loading="lazy" width="1920" height="1411" src={shibariumBlock} alt="Ryoshi in Shibarium Block"/>
        </figure>
      </section>,

      <section id="tokenomics">
        <div className="grid-x neg">
          <hr className="neg-top"/>
          <div className="sidebar">
            <p className="lbl">Tokenomics</p>
            <h3><small>Ryoshi's</small> plan starting<br/>
              <small>to take</small> shape <small>with</small> taxes</h3>

            <a className="btn primary"
               href="https://pancakeswap.finance/swap?outputCurrency=0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5"
               target="_blank" rel="nofollow" title="Buy Tokens">buy tokens</a>
          </div>

          <div className="details">
            <div className="grid-x">
              <div className="cell-6 sum remain">
                <p className="lbl">Shibarium info</p>
                <h2>What's<br/>
                  Planned<br/>
                  <span>in <img loading="lazy" width="75" height="100" src={arrowDwn}
                                title="Whats Planned in Taxes"/></span><br/>
                  taxes</h2>
              </div>

              <div className="cell-6 overall remain">
                <p className="lbl">Buy tax</p>
                <h2>5 <sup>%</sup></h2>
                <h3>0<sup>%</sup>&nbsp; transfer tax</h3>
              </div>
              <hr className="v-small-bot"/>
            </div>

            <div className="detail-list">
              <p className="lbl">Breakdown</p>
              <div className="detail">
                <div className="title">
                  <h3>Burn</h3>
                </div>
                <div className="tax">
                  <h3>1<sup>%</sup></h3>
                </div>
                <div className="desc">
                  <p className="fl">Ryoshi looked around, making eye contact with as many Shibarium Block's residents as
                    possible, "I know change can be scary, but we need to think about the long-term benefits. The two
                    percent burn on each transaction will change to just one percent." There was a collective sigh of
                    relief
                    from the crowd, a sign of unity.</p>
                </div>
              </div>
              <hr/>
              <div className="detail">
                <div className="title">
                  <h3>Liquidity</h3>
                </div>
                <div className="tax">
                  <h3>1<sup>%</sup></h3>
                </div>
                <div className="desc">
                  <p className="fl">"In addition to the burn tax, we will also be allocating one percent of each
                    transaction towards liquidity. This will help to stabilize our token's value and ensure that we can
                    continue to develop and grow our community. Also we are going to <a href="https://mudra.website/?certificate=yes&type=0&lp=0x2554938bb70426cb13a5b4202bbf59a2e91311fa" target="_blank" rel="nofollow" title="lock liquidity">lock liquidity</a>."</p>
                </div>
              </div>
              <hr/>
              <div className="detail">
                <div className="title">
                  <h3>Marketing</h3>
                </div>
                <div className="tax">
                  <h3>3<sup>%</sup></h3>
                </div>
                <div className="desc">
                  <p className="fl">"As some of you may already know," Ryoshi began, "we are implementing a necessary
                    tax that will go towards the development of Shibarium Block and be used to protect it. This tax will
                    be
                    used to improve our community, promote Shibarium Block, make partnerships and get listed on a
                    variety
                    of platforms for higher exposure."</p>
                </div>
              </div>
              <hr/>
              <div className="detail">
                <div className="title">
                  <h3><small>Total</small> Burned</h3>
                </div>
                <div className="tax">
                  <h3>51<sup>%</sup></h3>
                </div>
                <div className="desc">
                  <p className="fl">"I have some exciting news," he said, a smile spreading across his face. "As of
                    today, 51 percent of the total supply of our token has been burned. This is a huge accomplishment
                    for us," he continued. "It means that our token has a major advantage over other tokens in the
                    market. It also means that the value will continue to increase over time."</p>
                </div>
              </div>
              <hr/>
              <div className="detail">
                <div className="title">
                  <h3><small>Total</small> Supply</h3>
                </div>
                <div className="tax">
                  <h3>399<sup>tn</sup></h3>
                </div>
                <div className="desc">
                  <p className="fl">Ryoshi continues, "I want to talk about Doge's biggest problem. It's an inflationary
                    token, which means there's an infinite supply. Doge's community is strong, but the foundation upon
                    which it's built is weak. Ryoshi is deflationary! It means that we can build a stronger foundation,
                    and it will attract more investors to our block."</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr className="v-small"/>
        <div className="contract-ref justify-self">
          <p className="lbl neg">
            <span>BEP20 Contract</span>{" "}
            <button className="btn tiny outline" onClick={handleClick}>
              show{" "}
              <span className={showNew ? "new" : "old"}>
            {showNew ? "new" : "old"}
          </span>
            </button>
          </p>
          <p className={`reduced ${showNew ? "new" : "old"}`}>
            <span className={showNew ? "new" : "old"}>
              {showNew ? "0x0e5f989ce525acc4ee45506af91964f7f4c9f2e9" : "0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5"}
              </span>{" "}
          </p>
        </div>
      </section>,

      <Clipboard/>,

      <section id="community">
        <div className="grid-x">
          <div className="cell-6">
            <Parallax
              translateY={['300px', '-400px']}
              disabled={isMobile}
            >
              <h2>
                <br className="show-large"/>
                <br className="show-large"/>
                Building<br/>
                a<br/>
                Strong<br/>
                Community</h2>
              <p className="enlarged fl">
                Ryoshi wants to reward his biggest supporters. Those will be added onto the Honourable Shibarium's Plate
                of Fame, and will be rewarded with perks as we grow together.
              </p>
            </Parallax>
          </div>

          <div className="cell-6">
            <Parallax
              translateY={['-100px', '100px']}
              disabled={isMobile}
            >
              <Fameboard/>
            </Parallax>
          </div>
        </div>
      </section>,

      <section id="team">
        <hr/>
        <p className="lbl">Core team</p>
        <h3 className="text-center">toshiro &nbsp;&nbsp;&nbsp;<small>the ryoshi dev</small> marketing king &nbsp;&nbsp;
          <small>and the</small> &nbsp;&nbsp;&nbsp;mastermind &nbsp;&nbsp; <small>in association with</small><br
            className="show-large"/> Ezypx &nbsp;&nbsp;&nbsp;Design <small>production</small> studio &nbsp;&nbsp;
          <small>and</small> &nbsp;&nbsp;&nbsp;scorpion king management &nbsp;&nbsp;<small>community director</small><br
            className="show-large"/> &nbsp;&nbsp;<small>in partnership with</small> &nbsp;&nbsp;&nbsp;<small>whole
            Ryoshi</small> Community Marketing &nbsp;&nbsp;<small>presents</small></h3>
        <hr/>
      </section>,

      <section className="fw">
        <div className="marquee marquee-right">
          <div className="marquee-el" style={{animationDuration: '45s'}}><h2
            className="enlarge">RYOSHI&nbsp; - &nbsp;ShibFather Returns
            Home&nbsp; - &nbsp; RYOSHI&nbsp; - &nbsp;ShibFather Returns Home&nbsp; - &nbsp;</h2></div>
          <div className="marquee-el" style={{animationDuration: '45s'}}><h2
            className="enlarge">RYOSHI&nbsp; - &nbsp;ShibFather Returns
            Home&nbsp; - &nbsp; RYOSHI&nbsp; - &nbsp;ShibFather Returns Home&nbsp; - &nbsp;</h2></div>
        </div>
      </section>,
    ]
  );
};

export default Home;