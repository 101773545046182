import React, { useState, useRef } from 'react';
// scss
import './Clipboard.scss';

const Clipboard = () => {
  const addressRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleCopyClick = () => {
    addressRef.current.select();
    document.execCommand('copy');
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000);
  };


  return (
    <section id="contractCopy">
      <input
        ref={addressRef}
        type="text"
        defaultValue="0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5"
        style={{ position: 'fixed', left: '-9999px' }}
      />
      <button className="clipboard" onClick={handleCopyClick}>Copy Contract</button>
      {showMessage && <aside className="message">Done</aside>}
      <hr/>
    </section>
  );
};

export default Clipboard;