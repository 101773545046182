import React, { useEffect, useRef } from 'react';

const VideoPlayer = ({ src }) => {
  const videoRef = useRef();

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    const observer = new window.IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          if (currentVideoRef.paused) {
            currentVideoRef.muted = false;
            currentVideoRef.volume = 0.3;
            currentVideoRef.play();
          }
        } else {
          currentVideoRef.pause();
        }
      }, {
        threshold: 0.5,
      }
    );
    observer.observe(currentVideoRef);

    return () => {
      observer.unobserve(currentVideoRef);
    };
  }, []);

  return (
    <video ref={videoRef} loop playsInline controls autoplay>
      <source src={src} itemProp="contentUrl" />
    </video>
  );
};

export default VideoPlayer;