import React, {useState} from 'react';
// scss
import './Questionnaire.scss';

const QUESTIONS = [
  {
    question: "What kind of weapon would you prefer to carry?",
    options: [
      {
        option: "A silent but deadly set of claws",
        description: "Perfect for stealth attacks that require minimal noise.",
        matchingAnswers: [0],
      },
      {
        option: "A powerful long-range sniper rifle",
        description: "Ideal for long-range attacks with precision and accuracy.",
        matchingAnswers: [2],
      },
      {
        option: "A loud meow, to scare off any foes",
        description: "A non-lethal option that can intimidate enemies and ward off potential threats.",
        matchingAnswers: [1],
      },
    ],
  },
  {
    question: "What's your favourite food?",
    options: [
      {
        option: "Sushi, of course!",
        description: "A kitten who loves to indulge in the delicacy of sushi.",
        matchingAnswers: [1],
      },
      {
        option: "Ryoshi noodles with tuna, are always a classic",
        description: "A kitten who enjoys the classic and comforting dish of Ryoshi noodles with tuna.",
        matchingAnswers: [0],
      },
      {
        option: "CitCat addict",
        description: "A kitten who can't resist the sweet and addictive taste of CitCat.",
        matchingAnswers: [2],
      },
    ],
  },
  {
    question: "Do you have special skills?",
    options: [
      {
        option: "I'm a hacker and master of conspiracy",
        description: "A kitten who has mastered the art of hacking and enjoys being a master of conspiracy.",
        matchingAnswers: [2],
      },
      {
        option: "I'm Shibarium voice, a very vocal kitten",
        description: "A kitten who uses their voice and strong presence to make a difference and be heard in the Shibarium Block.",
        matchingAnswers: [1],
      },
      {
        option: "No, I'm just a regular kitten trying to survive",
        description: "A kitten who is just trying to get by and survive in the Shibarium Block.",
        matchingAnswers: [0],
      },
    ],
  },
  {
    question: "What is your main goal?",
    options: [
      {
        option: "To become a rich kitten from Shibarium Block",
        description: "A kitten who dreams of becoming wealthy and successful within the Shibarium Block.",
        matchingAnswers: [1],
      },
      {
        option: "To have some action and make a difference for the Shibarium Block",
        description: "A kitten who wants to make a positive impact and be a hero in the Shibarium Block.",
        matchingAnswers: [2],
      },
      {
        option: "To smoke catnip and have a peaceful life",
        description: "A kitten who desires a simple and peaceful life, enjoying the effects of catnip.",
        matchingAnswers: [0],
      },
    ],
  },
  {
    question: "Are you ready to fight for what you believe in?",
    options: [
      {
        option: "Yes, I will do whatever it takes to protect my kitten friends",
        description: "A kitten who is fiercely loyal and protective of their friends and will do anything to defend them.",
        matchingAnswers: [0, 1],
      },
      {
        option: "No, I would rather avoid confrontations and stay out of trouble",
        description: "A kitten who prefers to avoid confrontations and stay out of harm's way.",
        matchingAnswers: [2],
      },
    ],
  },
  {
    question: "How would you describe your fighting style?",
    options: [
      {
        option: "I prefer to use my agility and speed",
        description: "A kitten who relies on their quick reflexes and nimble movements to outmaneuver their opponent.",
        matchingAnswers: [0],
      },
      {
        option: "I rely on my strength and brute force",
        description: "A kitten who uses their strength and brute force to overpower their enemy.",
        matchingAnswers: [2],
      },
      {
        option: "I use a combination of strategy and technique",
        description: "A kitten who employs a combination of strategy and technique to outsmart their enemies.",
        matchingAnswers: [1],
      },
    ],
  },
  {
    question: "What is your preferred mode of transportation?",
    options: [
      {
        option: "I like to move quickly and stealthily on foot",
        description: "A kitten who prefers to move quickly and quietly on foot to avoid attracting attention.",
        matchingAnswers: [0],
      },
      {
        option: "I prefer to travel by bike for speed and agility",
        description: "A kitten who enjoys the speed and agility of traveling by bike.",
        matchingAnswers: [2],
      },
      {
        option: "I like to ride in style in a customized car",
        description: "A kitten who likes to ride in style and stand out with a customized car.",
        matchingAnswers: [1],
      },
    ],
  },
  {
    question: "How many cat-lives are left?",
    options: [
      {
        option: "Purrfect full 9 lives",
        description: "The kitten has all nine of its lives intact, very careful.",
        matchingAnswers: [1],
      },
      {
        option: "More than 5",
        description: "The kitten has more than five lives remaining.",
        matchingAnswers: [0],
      },
      {
        option: "Last one",
        description: "The kitten has only one life remaining. Seen it all.",
        matchingAnswers: [2],
      },
    ],
  },
  {
    question: "What is your opinion on outsiders?",
    options: [
      {
        option: "They must prove themselves worthy",
        description: "The kitten believes that outsiders should prove themselves before being allowed into Shibarium Block",
        matchingAnswers: [2],
      },
      {
        option: "I welcome all newcomers with open paws",
        description: "The kitten is friendly towards newcomers and welcomes them into the community.",
        matchingAnswers: [1],
      },
      {
        option: "Outsiders are not to be trusted",
        description: "The kitten is suspicious of outsiders and believes they should be closely watched.",
        matchingAnswers: [0],
      },
    ],
  },
];

const ANSWERS = [
  {
    title: "Silent Paw Division",
    description: "A division of highly skilled and agile kittens who rely on their claws to silently take out their enemies.",
  },
  {
    title: "Shibarium Security ",
    description: "A group of tough kittens who rely on their intimidating presence and loud meows to keep the Shibarium Block safe.",
  },
  {
    title: "Nine Lives Brigade",
    description: "A highly trained group of marksmen kittens who specialize in long-range attacks and take out their foes from a safe distance.",
  },
];

function Intro({onStart}) {
  return (
    <aside id="quiz" className="widget starter">
      <header>
        <h3 className="widget-title"><small>Are</small> <span>you</span> <small>up for</small> <small>the</small> <span>Challenge</span></h3>
        <hr/>
        <p>Discover Your Perfect Fit: Take the Ryoshi Division Quiz and Find Your Ideal Community</p>
        <button className="btn primary" onClick={onStart}>Take Quiz</button>
      </header>
    </aside>
  );
}

function Questionnaire() {
  const [currentStep, setCurrentStep] = useState(-1);
  const [answers, setAnswers] = useState(Array(QUESTIONS.length).fill(-1));
  const [completed, setCompleted] = useState(false);

  const handleOptionClick = (questionIndex, optionIndex) => {
    const newAnswers = [...answers];
    newAnswers[questionIndex] = optionIndex;
    setAnswers(newAnswers);
  };

  const handlePreviousClick = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleNextClick = () => {
    if (currentStep === QUESTIONS.length - 1) {
      setCompleted(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleResetClick = () => {
    setCurrentStep(0);
    setAnswers(Array(QUESTIONS.length).fill(-1));
    setCompleted(false);
  };

  const calculateResult = () => {
    const answerCounts = Array(ANSWERS.length).fill(0);
    for (let i = 0; i < QUESTIONS.length; i++) {
      const selectedOption = answers[i];
      if (selectedOption === -1) {
        return null;
      }
      const matchingAnswers = QUESTIONS[i].options[selectedOption].matchingAnswers;
      matchingAnswers.forEach((matchingAnswerIndex) => {
        answerCounts[matchingAnswerIndex]++;
      });
    }
    const maxAnswerCount = Math.max(...answerCounts);
    const bestMatchingAnswers = [];
    for (let i = 0; i < ANSWERS.length; i++) {
      if (answerCounts[i] === maxAnswerCount) {
        bestMatchingAnswers.push(ANSWERS[i]);
      }
    }
    const result = bestMatchingAnswers[Math.floor(Math.random() * bestMatchingAnswers.length)];
    return result;
  };

  const totalQuestions = QUESTIONS.length;
  const currentQuestionNumber = currentStep + 1;

  const result = calculateResult();

  if (currentStep === -1) {
    return (
      <Intro onStart={() => setCurrentStep(0)}/>
    );
  } else if (completed) {
    return (
      <aside id="quiz" className="widget result">
        <header>
          {result !== null && (
            <div className="answer">
              <h4 className="widget-title">Hey, You were assigned to</h4>
              <hr/>
              <h3>{result.title}</h3>
              <p>{result.description}</p>
            </div>
          )}
          <div className="btn-group">
            <a href="https://t.me/ryoshitokenofficial" className="btn primary" rel="dofollow" title="Join Us" target="_blank">Join Us</a>
            <button className="btn outline dark" onClick={handleResetClick}>Try Again</button>
          </div>
        </header>
      </aside>
    );
  } else {
    const currentQuestion = QUESTIONS[currentStep];
    return (
      <aside id="quiz" className="widget quiz">
        <header>
          <p><small>Question {currentQuestionNumber} / {totalQuestions}
            <button
              onClick={handlePreviousClick}
              disabled={currentStep === 0}
            >
              &nbsp;&nbsp;—&nbsp;&nbsp;Step Back
            </button>
          </small></p>
          <h4>{currentQuestion.question}</h4>
          <ul className="options">
            {currentQuestion.options.map((option, optionIndex) => (
              <li key={optionIndex}>
                <button
                  className={`option ${
                    answers[currentStep] === optionIndex ? "selected" : ""
                  }`}
                  onClick={() => handleOptionClick(currentStep, optionIndex)}
                >
                  <span className="letter">{String.fromCharCode(65 + optionIndex)}. </span>
                  <sapn className="cnt">
                    <span className="title">{option.option}</span>
                    <span className="desc">{option.description}</span>
                  </sapn>
                </button>
              </li>
            ))}
          </ul>
          <button
            className="next btn small primary"
            onClick={handleNextClick}
            disabled={answers[currentStep] === -1}
          >
            {currentStep === QUESTIONS.length - 1 ? "Finish" : "Next"}
          </button>
        </header>
      </aside>
    );
  }
}

export default Questionnaire;