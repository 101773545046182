import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';

// components
import Header from './components/Header';
import Footer from './components/Footer';

// pages
import Home from './pages/Home/Home';
import Whitepaper from "./pages/Whitepaper/Whitepaper";
import Roadmap from "./pages/Whitepaper/Roadmap";
import Tokenomics from "./pages/Whitepaper/Tokenomics";
import GettingStarted from "./pages/Whitepaper/GettingStarted";
import Migration from "./pages/FAQs/Migration";


// scss
import './App.scss';

const App = () => {
  return (
    <Router>
      <ParallaxProvider>
        <Header/>
        <Routes>
          <Route exact path="/" element={<Home/>}/>
          <Route exact path="/whitepaper" element={<Whitepaper/>}/>
          <Route exact path="/whitepaper/roadmap" element={<Roadmap/>}/>
          <Route exact path="/whitepaper/tokenomics" element={<Tokenomics/>}/>
          <Route exact path="/whitepaper/getting-started" element={<GettingStarted/>}/>
          <Route exact path="/faq/migration" element={<Migration/>}/>
        </Routes>
        <Footer/>
      </ParallaxProvider>
    </Router>
  );
};

export default App;
