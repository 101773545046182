import React, {useEffect, useRef, useState} from 'react';
import {Helmet} from 'react-helmet';

// components
import Breadcrumbs from './../../components/BreadCrumbs';
import WhitePaperNav from "../../components/WhitePaperNav";

// scss
import './Whitepaper.css';
import './../../scss/globals/entry.scss';

// images
import logoSmall from "../../img/ryoshilogo.png";


const Tokenomics = () => {
  const addressRef = useRef(null);
  const [showMessage, setShowMessage] = useState(false);

  const handleCopyClick = () => {
    addressRef.current.select();
    document.execCommand('copy');
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 3000);
  };

  const [socialUrl] = useState(window.location.href);

  const [activeListItem, setActiveListItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const headings = document.querySelectorAll("h2, h3, h4");
      const lastHeadingInView = Array.from(headings).reverse().find((heading) => {
        const bounding = heading.getBoundingClientRect();
        return bounding.bottom >= 0 && bounding.bottom <= window.innerHeight;
      });
      if (lastHeadingInView) {
        const id = lastHeadingInView.getAttribute("id");
        setActiveListItem(id);
      } else {
        const firstListItem = document.querySelector(".sidebar li:first-of-type");
        const listItemBounding = firstListItem.getBoundingClientRect();
        if (listItemBounding.top > 0) {
          setActiveListItem(null);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const jsonLd = {
    "@type": "Organization",
    "name": "Ryoshi Token",
    "url": "https://ryoshitoken.com/",
    "sameAs": [
      "https://www.instagram.com/ryoshitoken",
      "https://twitter.com/TokenRyoshi",
      "https://www.facebook.com/Ryoshitoken/",
    ],
    "logo": logoSmall,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hi@ryoshitoken.com",
      "contactType": "Customer service"
    },
    "tableOfContents": {
      "@type": "ItemList",
      "name": "Table of Contents",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": "1",
          "name": "Token Information",
          "url": socialUrl + "#information"
        },
        {
          "@type": "ListItem",
          "position": "2",
          "name": "Token Allocation",
          "url": socialUrl + "#allocation"
        },
        {
          "@type": "ListItem",
          "position": "3",
          "name": "Token Tax",
          "url": socialUrl + "#tax"
        }
      ]
    }
  };

  return (
    [
      <Helmet>
        <title>Ryoshi — ShibFather Returns Home, deflationary Ryoshi BSC token</title>
        <link rel="canonical" href="https://ryoshitoken.com/whitepaper"/>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>,

      <Breadcrumbs/>,

      <p className="time-stamp">Page last updated: <time>March 27, 2023</time></p>,

      <section className="entry">
        <div className="grid-x justify">
          <div className="cell-3 wp-nav">
            <input id="bpmenu" type="checkbox"/>
            <label htmlFor="bpmenu">
              Blueprint Menu
            </label>

            <WhitePaperNav/>
          </div>
          <div className="cell-6 cnt">
            <h1>Tokenomics</h1>

            <p className="enlarged">
              The Ryoshi Token (RYOSHI) originally launched on the Binance Smart Chain with a total supply of 1
              quadrillion, but a new version (RYOSHI V2) was relaunched on the same network with total supply
              of 339,999,999,999,999. The relaunch also brought changes to the token tax system, which would allow for
              further growth and development of the project.
            </p>

            <hr/>

            <h2 id="information">Token Information</h2>
            <ul className="as-table two">
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Contract</span>
                  </li>
                  <li style={{width: 'calc(100% - 160px)'}}>
                    <span className="reduced" onClick={handleCopyClick}>
                      <input
                        ref={addressRef}
                        type="text"
                        defaultValue="0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5"
                        style={{position: 'fixed', left: '-9999px'}}
                      />
                      <span id="contract">0xB350aEBAEDB1eD3269b0E25D5E593a9bB4b9f9d5</span> <i
                      data-ico="clipboard"></i> {showMessage && <aside className="message">Copied</aside>}</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Network</span>
                  </li>
                  <li style={{width: 'calc(100% - 160px)'}}>
                    <span>Binance Smart Chain (BEP20)</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Name (Ticker)</span>
                  </li>
                  <li style={{width: 'calc(100% - 160px)'}}>
                    <span>Ryoshi Token (RYOSHI)</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Total Supply</span>
                  </li>
                  <li style={{width: 'calc(100% - 160px)'}}>
                    <span>339,999,999,999,999 RYOSHI</span>
                  </li>
                </ul>
              </li>
            </ul>
            <p>It is recommended to only buy Ryoshi Tokens through decentralized exchanges (DEX) such as PancakeSwap,
              PooCoin, DexTools, etc. This is because DEX platforms provide greater security and transparency compared
              to sending BNB directly to the contract.</p>

            <h2 id="allocation">Token Allocation</h2>
            <ul className="as-table two">
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Burnt</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>172,649,852,601,028</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>50.7794%</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Liquidity</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>68,043,033.8793</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>4.8%</span>
                  </li>
                </ul>
              </li>
            </ul>
            <p>Team <a
              href="https://mudra.website/?certificate=yes&type=0&lp=0x2554938bb70426cb13a5b4202bbf59a2e91311fa"
              title="locked the liquidity for a year" rel="nofollow" target="_blank">locked the liquidity for a year</a>.
              This means that the liquidity cannot be withdrawn from the pool for a period of one year, which can help
              provide stability to the token price and increase investor confidence in the project.</p>

            <h2 id="taxes">Token Taxes</h2>
            <ul className="as-table two">
              <li className="heading">
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Distribution</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>Buy Tax %</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>Sell Tax %</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Burn</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>1</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>1</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Liquidity</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>1</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>1</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Marketing</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>3</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>3</span>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li style={{width: '160px'}}>
                    <span>Total Tax</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>5</span>
                  </li>
                  <li style={{width: 'calc((100% - 160px) / 2)'}}>
                    <span>5</span>
                  </li>
                </ul>
              </li>
            </ul>
            <p>It's worth noting that Ryoshi Tokens do not have any transfer tax. This means that you can transfer your
              tokens to other wallets or users without incurring any additional fees.</p>
          </div>

          <div className="cell-3 sidebar">
            <input id="tblcnt" type="checkbox"/>
            <label htmlFor="tblcnt">
              Table of contents
            </label>

            <ul>
              <li className={activeListItem === 'information' ? 'active' : ''}><a href="#information">Token
                Information</a>
              </li>
              <li className={activeListItem === 'allocation' ? 'active' : ''}><a href="#allocation">Token Allocation</a>
              </li>
              <li className={activeListItem === 'taxes' ? 'active' : ''}><a href="#taxes">Token Taxes</a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    ]
  );
};

export default Tokenomics;