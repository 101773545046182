import React from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'

// scss
import './BreadCrumbs.scss';

const Breadcrumbs = () => {
  const location = useLocation()
  const pathnames = location.pathname.split('/').filter(x => x)

  return (
    <>
      <div className="breadcrumbs" itemScope itemType="https://schema.org/BreadcrumbList">
        <ol className="breadcrumb-schema">
          <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
            <Link className="btn-as-arrow" itemProp="item" to="/">
              <span itemProp="name">Home</span>
            </Link>
            <meta itemProp="position" content={1} />
          </li>
          {pathnames.map((pathname, index) => {
            const to = `/${pathnames.slice(0, index + 1).join('/')}`
            return (
              <li itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link className="btn-as-arrow" itemProp="item" to={to} title={pathname}>
                  <span itemProp="name">{pathname}</span>
                </Link>
                <meta itemProp="position" content={index + 2}/>
              </li>
            )
          })}
        </ol>
      </div>
    </>
  )
}

export default Breadcrumbs;