import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet';
import WhitePaperNav from "../../components/WhitePaperNav";

// components
import Breadcrumbs from './../../components/BreadCrumbs';

// scss
import './Whitepaper.css';
import './../../scss/globals/entry.scss';

// images
import logoSmall from "../../img/ryoshilogo.png";
import ryoshiEco from "../../img/ryoshi-ecosystem-wp.webp";
import citCat from "../../img/citcatapp.webp";


const Whitepaper = () => {
  const [socialUrl] = useState(window.location.href);

  const [activeListItem, setActiveListItem] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const headings = document.querySelectorAll("h2, h3, h4");
      const lastHeadingInView = Array.from(headings).reverse().find((heading) => {
        const bounding = heading.getBoundingClientRect();
        return bounding.bottom >= 0 && bounding.bottom <= window.innerHeight;
      });
      if (lastHeadingInView) {
        const id = lastHeadingInView.getAttribute("id");
        setActiveListItem(id);
      } else {
        const firstListItem = document.querySelector(".sidebar li:first-of-type");
        const listItemBounding = firstListItem.getBoundingClientRect();
        if (listItemBounding.top > 0) {
          setActiveListItem(null);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const jsonLd = {
    "@type": "Organization",
    "name": "Ryoshi Token",
    "url": "https://ryoshitoken.com/",
    "sameAs": [
      "https://www.instagram.com/ryoshitoken",
      "https://twitter.com/TokenRyoshi",
      "https://www.facebook.com/Ryoshitoken/",
    ],
    "logo": logoSmall,
    "contactPoint": {
      "@type": "ContactPoint",
      "email": "hi@ryoshitoken.com",
      "contactType": "Customer service"
    },
    "tableOfContents": {
      "@type": "ItemList",
      "name": "Table of Contents",
      "itemListElement": [
        {
          "@type": "ListItem",
          "position": "1",
          "name": "Ryoshi Ecosystem",
          "url": socialUrl + "#ecosystem"
        },
        {
          "@type": "ListItem",
          "position": "2",
          "name": "Ryo Cash",
          "url": socialUrl + "#ryocash"
        },
        {
          "@type": "ListItem",
          "position": "3",
          "name": "CitCat App",
          "url": socialUrl + "#citcat"
        },
        {
          "@type": "ListItem",
          "position": "4",
          "name": "Whisker Swap",
          "url": socialUrl + "#swap"
        },
        {
          "@type": "ListItem",
          "position": "5",
          "name": "Whisker Dex",
          "url": socialUrl + "#dex"
        },
        {
          "@type": "ListItem",
          "position": "6",
          "name": "Trusted Team",
          "url": socialUrl + "#team"
        }
      ]
    }
  };

  return (
    [
      <Helmet>
        <title>Ryoshi — ShibFather Returns Home, deflationary Ryoshi BSC token</title>
        <link rel="canonical" href="https://ryoshitoken.com/whitepaper"/>
        <script type="application/ld+json">
          {JSON.stringify(jsonLd)}
        </script>
      </Helmet>,

      <Breadcrumbs/>,

      <p className="time-stamp">Page last updated: <time>March 28, 2023</time></p>,

      <section className="entry">
        <div className="grid-x justify">
          <div className="cell-3 wp-nav">
            <input id="bpmenu" type="checkbox"/>
            <label htmlFor="bpmenu">
              Blueprint Menu
            </label>

            <WhitePaperNav/>
          </div>
          <div className="cell-6 cnt">
            <h1>Ryoshi Blueprint<br/>
              <small>aka</small> &nbsp;&nbsp;Whitepaper</h1>

            <p className="enlarged">
              Introducing Ryoshi, a community-driven ecosystem that has achieved remarkable success without relying on a
              marketing wallet. The first version of Ryoshi hit a staggering $45 million market cap, and with proper
              funding, the possibilities for development and listings are truly limitless.
            </p>

            <hr/>

            <h2 id="ecosystem">Ryoshi Ecosystem</h2>
            <p>RYOSHI is the utility token of the Ryoshi Ecosystem.</p>

            <figure>
              <img loading="lazy" width="800" height="608" src={ryoshiEco} alt="Ryoshi Ecosystem"/>
            </figure>

            <h2 id="ryocash">Ryo Cash</h2>
            <p>As part of our ongoing efforts to expand the Ryoshi ecosystem, we're excited to announce the upcoming
              addition of Ryo Cash, our very own stable coin. With Ryo Cash, users will enjoy a stable and reliable
              cryptocurrency that's perfect for everyday transactions.</p>

            <h2 id="citcat">CitCat App</h2>
            <figure>
              <img loading="lazy" width="800" height="392" src={citCat} alt="Ryoshi Ecosystem"/>
            </figure>
            <p>We're also thrilled to partner with a leading debit card provider for Ryoshi V2, which will enable users
              to load RyoCash onto their debit card and use it just like cash. This is a major milestone for Ryoshi, as
              it brings us one step closer to our goal of making cryptocurrency accessible to everyone.</p>

            <h2 id="swap">Whisker Swap</h2>
            <p>Whisker Swap will operate on the Shibarium network. Whisker Swap offers a simple and intuitive user
              interface, making it easy for users to trade their favorite cryptocurrencies. With low fees and fast
              transaction times, Whisker Swap provides a seamless trading experience that is accessible to everyone.</p>

            <h2 id="dex">Whisker Dex</h2>
            <p>And that's not all. In a world dominated by Dogecoin and other popular meme tokens, Ryoshi is proud to
              announce the launch of our very own Ryoshi Dex on the Shibarium blockchain. With this exciting new
              platform, users will enjoy lightning-fast transactions, low fees, and a secure trading environment.</p>

            <h2 id="team">Trusted Team</h2>
            <p>At Team Ryoshi, we've worked hard to earn your trust over the past few years, and we're committed to
              continuing our mission to build the best possible ecosystem for our community. Join us on this exciting
              journey and discover all the amazing things that Ryoshi has to offer!</p>
          </div>

          <div className="cell-3 sidebar">
            <input id="tblcnt" type="checkbox"/>
            <label htmlFor="tblcnt">
              Table of contents
            </label>

            <ul>
              <li className={activeListItem === 'ecosystem' ? 'active' : ''}><a href="#ecosystem">Ryoshi Ecosystem</a>
              </li>
              <li className={activeListItem === 'ryocash' ? 'active' : ''}><a href="#ryocash">Ryo Cash</a></li>
              <li className={activeListItem === 'citcat' ? 'active' : ''}><a href="#citcat">CitCat App</a></li>
              <li className={activeListItem === 'swap' ? 'active' : ''}><a href="#swap">Whisker Swap</a></li>
              <li className={activeListItem === 'dex' ? 'active' : ''}><a href="#dex">Whisker Dex</a></li>
              <li className={activeListItem === 'team' ? 'active' : ''}><a href="#team">Trusted Team</a></li>
            </ul>
          </div>
        </div>
      </section>
    ]
  );
};

export default Whitepaper;