import React, { useState, useRef } from 'react';
// scss
import './Focus.scss';
// images
import ryoshi from "../img/negative-ryoshi.svg";

const Focus = () => {
  const [focusPos, setFocusPos] = useState({ top: 0, left: 0 });
  const focusRef = useRef(null);

  const handleMouseEnter = () => {
    focusRef.current.classList.add('in-view');
  }

  const handleMouseLeave = () => {
    focusRef.current.classList.remove('in-view');
  }

  const handleMouseMove = (e) => {
    const focusHalfWidth = focusRef.current.offsetWidth;
    setFocusPos({
      top: e.pageY - focusHalfWidth,
      left: e.pageX - focusHalfWidth
    });
  }

  return (
    <section id="focus-title" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onMouseMove={handleMouseMove}>
      <div ref={focusRef} className="focus" style={{ position: "absolute", top: focusPos.top, left: focusPos.left }}></div>
      <h1><img src={ryoshi} alt="RYOSHI" /></h1>
    </section>
  );
};

export default Focus;